.msgmee-login-container {
  max-width: 280px;
  border: 1px solid #bfbfb9;
  border-radius: 10px;
  padding: 20px;
  margin: 10px;

}

.msgmee-login-container h1 {
  margin-bottom: 0;
}

.msgmee-login-container form {
  margin-top: 5px;
}

.get-otp-btn {
  margin-top: 10px;
  background-color: #81c14b !important;
  color: white !important;
  border-radius: 8px;
}

.login-or {
  text-align: center;
}


.login-form-phone {
  margin-top: 40px;
}

.login-term-text p {
  font-size: 11px !important;
}

.login-term-text p {
  margin: 0px;
  padding: 0;
}

.login-term-text a {
  color: rgb(0, 81, 255);
}

.login-term-text a:hover {
  color: darkblue;
}

.otp-label {
  display: flex;
  justify-content: space-between;
  /* Add space between labels */
  align-items: center;
  /* Vertically center the labels */
}

.otp-label h4,
h5 {
  margin: 0;
}

.otp-label h5 {
  color: rgb(0, 81, 255);
  cursor: pointer;
}

.otp-label h5:hover {
  color: darkblue;
}

.msgmee-login-fields-container {}

.parent-login-wrapper {
  position: absolute;
  display: flex;
  flex-direction: row;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: space-between;
}

.instruction-container{
  flex: 1;
  padding-top: 40px;
}
.instruction-container h4{
  width: 300px;
  color: black; 
}

.shrink-balancer{
  width: 100px;
  flex: 0.5;
}



@media (max-width: 700px) {

  .parent-login-wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    top: unset;
    left: unset;
    transform: translate(0%, 0%);
    justify-content:center;
  }
  .instruction-container{
    display: none;

  }
  .shrink-balancer{
    display: none;
  }
  
}

@media (max-width: 300px) {
  .msgmee-login-container {
    /* Styles specific to screens less than 600px wide */
    /* Add your responsive styles here */
    top: 2%;
    right: 0;
    left: 0;
    width: auto;
  }
}